<template>
  <div style="background-color: #F5F5F5;height: 100%">
    <van-tabs v-model="active" border title-active-color="#387ff5" color="#387ff5" line-height="1px" @click="getList">

      <van-tab title="服务请求大厅" name='1' type="line" style="background-color: #F5F5F5;"  >
        <van-dropdown-menu active-color="#3583f0" :overlay="overlay">
          <van-dropdown-item  style="font-size: 15px;" title="全部" :options="menuOptionAll" @open="clearAll(0)" @close="clearAll(0)"/>
          <van-dropdown-item  v-model="menuValueType" :options="menuOptionType" title="服务类型" @change="clearAll(1)" @open="openOverlay"/>
          <van-dropdown-item  v-model="menuValueName" :options="menuOptionName" title="服务名称" @change="clearAll(1)" @open="openOverlay"/>
          <van-dropdown-item  v-model="menuValueBenefit" :options="menuOptionBenefit"  title="服务收益" @change="clearAll(1)" @open="openOverlay"/>
        </van-dropdown-menu>
        <!-- <van-pull-refresh v-model="isLoading" :head-height="80" @refresh="refresh('51')"> -->
          <div>
            <div style="margin:2% 0 0 4%">
              <div class="title-height">
                <div class="title-num">共{{serviceTypeLength}}条数据</div>
              </div>
            </div>
            <div class="list" v-if="serviceList.length>0">
              <van-list v-model="loading" :finished="finished" offset="0" finished-text="没有更多了" @load="getDataList('51')">
                <div class="item" v-for="service in serviceList" @click="toDetail(service.status,service.id,service.serviceId,service.applyUserId,service.subarea)">
                  <div class="head">
                    <div v-if="service.status===51" style="background-color:#666666;" class="tag-status">
                      <div class="content">待接单</div>
                    </div>
                    <div class="name">{{ service.name }}</div>
                    <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                    <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                    <div class="typeName">{{ service.typeName }}</div>
                  </div>
                  <div style="display: flex;margin-top: 2%;">
                    <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                    <div class="info">{{service.userName}}</div>
                  </div>
                  <div style="display: flex;margin-top: 2%;">
                    <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                    <div class="info">{{ service.fullName }}</div>
                    <van-image class="goto-image" :src="require('@/assets/img/goto.png')"></van-image>
                  </div>
                  <div style="display: flex;margin-top: 2%;position:relative;">
                    <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                    <div class="info">{{service.createDate}}</div>
                  </div>
                </div>
              </van-list>

            </div>
            <van-empty description="暂无数据" v-else />
          </div>
        <!-- </van-pull-refresh> -->

      </van-tab>

      <van-tab title="我提供的服务" name='2' type="line">
        <van-tabs v-model="active_2" line-height="0" title-active-color="#387ff5" @click="changeType">
          <van-tab title="全部" type="line" name="0">
            <!-- <van-pull-refresh v-model="isLoading" @refresh="refresh('0')"> -->
              <div style="">
                <div style="margin:2% 0 0 4%">
                  <div class="title-height">
                    <div class="title-num">共{{serviceTypeLength}}条数据</div>
                  </div>
                </div>
                <div class="list" v-if="serviceList.length>0">
                  <van-list v-model="loading" :finished="finished" offset="0" finished-text="没有更多了" @load="getDataList('51')">
                    <div class="item" v-for="service in serviceList">
                      <div class="head">
                        <div v-if="service.status===20" style="background-color:#3480F0;" class="tag-status">
                          <div class="content">待服务</div>
                        </div>
                        <div v-if="service.status===30" style="background-color:#ffa93a;" class="tag-status">
                          <div class="content">待评价</div>
                        </div>
                        <div v-if="service.status===40" style="background-color:#0ab9b3;" class="tag-status">
                          <div class="content">已评价</div>
                        </div>
                        <div v-if="service.status===51" style="background-color:#666666;" class="tag-status">
                          <div class="content">待接单</div>
                        </div>
                        <div v-if="service.status===54" style="background-color:red;" class="tag-status">
                          <div class="content">已退单，待审核</div>
                        </div>
                        <div class="name">{{ service.name }}</div>
                        <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                        <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                        <div @click="callPhone(service.mobile)">
                          <van-image class="phone-image" :src="require('@/assets/img/phone.png')"></van-image>
                        </div>
                      </div>
                      <div style="display: flex;margin-top: 2%;">
                        <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                        <div class="info">{{service.userName}}</div>
                        <div class="typeName">{{ service.typeName }}</div>

                      </div>
                      <div style="display: flex;margin-top: 2%;">
                        <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                        <div class="info">{{ service.fullName }}</div>
                      </div>
                      <div style="display: flex;margin-top: 2%;position:relative;">
                        <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                        <div class="info">{{ service.provideDate }}</div>
                        <div @click="toDetail(service.status,service.id)">
                          <van-image class="goto-image-other" :src="require('@/assets/img/goto.png')"></van-image>
                        </div>

                      </div>
                    </div>
                  </van-list>
                </div>
                <van-empty description="暂无数据" v-else />
              </div>
            <!-- </van-pull-refresh> -->
          </van-tab>
          <van-tab title="待服务" type="line" name="20" >
            <!-- <van-pull-refresh v-model="isLoading" @refresh="refresh('20')"> -->
              <div style="">
                <div style="margin:2% 0 0 4%">
                  <div class="title-height">
                    <div class="title-num">共{{serviceTypeLength}}条数据</div>
                  </div>
                </div>
                <div class="list" v-if="serviceList.length>0">
                  <van-list v-model="loading" :finished="finished" offset="0" finished-text="没有更多了" @load="getDataList('51')">
                    <div class="item" v-for="service in serviceList" v-if="service.status===20">
                      <div class="head">
                        <div v-if="service.status===20" style="background-color:#3480F0;" class="tag-status">
                          <div class="content">待服务</div>
                        </div>
                        <div class="name">{{ service.name }}</div>
                        <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                        <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                        <div @click="callPhone(service.mobile)">
                          <van-image class="phone-image" :src="require('@/assets/img/phone.png')"></van-image>
                        </div>
                      </div>
                      <div style="display: flex;margin-top: 2%;">
                        <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                        <div class="info">{{service.userName}}</div>
                        <div class="typeName">{{ service.typeName }}</div>

                      </div>
                      <div style="display: flex;margin-top: 2%;">
                        <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                        <div class="info">{{ service.fullName }}</div>
                      </div>
                      <div style="display: flex;margin-top: 2%;position:relative;">
                        <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                        <div class="info">{{ service.provideDate }}</div>
                        <div @click="toDetail(service.status,service.id)">
                          <van-image class="goto-image-other" :src="require('@/assets/img/goto.png')"></van-image>
                        </div>
                      </div>
                    </div>
                  </van-list>
                </div>
                <van-empty description="暂无数据" v-else />
              </div>
            <!-- </van-pull-refresh> -->
          </van-tab>
          <van-tab title="待评价" type="line" name="30" >
            <!-- <van-pull-refresh v-model="isLoading" @refresh="refresh('30')"> -->
              <div style="">
                <div style="margin:2% 0 0 4%">
                  <div class="title-height">
                    <div class="title-num">共{{serviceTypeLength}}条数据</div>
                  </div>
                </div>
                <div class="list" v-if="serviceList.length>0">
                  <van-list v-model="loading" :finished="finished" offset="0" finished-text="没有更多了" @load="getDataList('51')">
                    <div class="item" v-for="service in serviceList" v-if="service.status===30">
                      <div class="head">
                        <div v-if="service.status===30" style="background-color:#ffa93a;" class="tag-status">
                          <div class="content">待评价</div>
                        </div>
                        <div class="name">{{ service.name }}</div>
                        <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                        <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                        <div @click="callPhone(service.mobile)">
                          <van-image class="phone-image" :src="require('@/assets/img/phone.png')"></van-image>
                        </div>
                      </div>
                      <div style="display: flex;margin-top: 2%;">
                        <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                        <div class="info">{{service.userName}}</div>
                        <div class="typeName">{{ service.typeName }}</div>

                      </div>
                      <div style="display: flex;margin-top: 2%;">
                        <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                        <div class="info">{{ service.fullName }}</div>
                      </div>
                      <div style="display: flex;margin-top: 2%;position:relative;">
                        <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                        <div class="info">{{ service.provideDate }}</div>
                        <div @click="toDetail(service.status,service.id)">
                          <van-image class="goto-image-other" :src="require('@/assets/img/goto.png')"></van-image>
                        </div>
                      </div>
                    </div>
                  </van-list>
                </div>
                <van-empty description="暂无数据" v-else />
              </div>
            <!-- </van-pull-refresh> -->
          </van-tab>
          <van-tab title="已评价" type="line" name="40" >
            <!-- <van-pull-refresh v-model="isLoading" @refresh="refresh('40')"> -->
              <div style="">
                <div style="margin:2% 0 0 4%">
                  <div class="title-height">
                    <div class="title-num">共{{serviceTypeLength}}条数据</div>
                  </div>
                </div>
                <div class="list" v-if="serviceList.length>0">
                  <van-list v-model="loading" :finished="finished" offset="0" finished-text="没有更多了" @load="getDataList('51')">
                    <div class="item" v-for="service in serviceList" v-if="service.status===40" >
                      <div class="head">
                        <div v-if="service.status===40" style="background-color:#0ab9b3;" class="tag-status">
                          <div class="content">已评价</div>
                        </div>
                        <div class="name">{{ service.name }}</div>
                        <div class="benefit" v-if="service.serviceProfit===0">/(无偿)</div>
                        <div class="benefit" v-if="service.serviceProfit===1">/(低偿)</div>
                        <div @click="callPhone(service.mobile)">
                          <van-image class="phone-image" :src="require('@/assets/img/phone.png')"></van-image>
                        </div>
                      </div>
                      <div style="display: flex;margin-top: 2%;">
                        <van-image class="all-image" :src="require('@/assets/img/person.png')"></van-image>
                        <div class="info">{{service.userName}}</div>
                        <div class="typeName">{{ service.typeName }}</div>

                      </div>
                      <div style="display: flex;margin-top: 2%;">
                        <van-image class="location-image" :src="require('@/assets/img/location.png')"></van-image>
                        <div class="info">{{ service.fullName }}</div>
                      </div>
                      <div style="display: flex;margin-top: 2%;position:relative;">
                        <van-image class="all-image" :src="require('@/assets/img/time.png')"></van-image>
                        <div class="info">{{ service.provideDate }}</div>
                        <div @click="toDetail(service.status,service.id)">
                          <van-image class="goto-image-other" :src="require('@/assets/img/goto.png')"></van-image>
                        </div>
                      </div>
                    </div>
                  </van-list>

                </div>
                <van-empty description="暂无数据" v-else />
              </div>
            <!-- </van-pull-refresh> -->
          </van-tab>
        </van-tabs>
      </van-tab>

    </van-tabs>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

export default {

  components :{
    topBar

  },
  name: "older-service",
  data() {
    return {
      page: 0,
      isLoading:false,
      loading: false,
      finished:false,
      active: 0,
      active_1:0,
      active_2:0,
      menuValueType: "",
      menuValueName: "",
      menuValueBenefit:"",
      menuOptionAll: [

      ],
      menuOptionType: [
      ],
      menuOptionName: [
      ],
      menuOptionBenefit:[
        { text: "无偿服务", value: 0 },
        { text: "低偿服务", value: 1 },

      ],
      serviceList:[],
      serviceTypeLength:0,
      overlay: false
    };
  },
  mounted() {
    this.getDataList('51');
    this.getServiceType();
    this.getServiceName();
  },
  methods: {
    refresh(type){
      this.serviceTypeLength = 0
      this.finished = false
      this.page=0
      this.serviceList=[]
      this.getDataList(type)
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
    callPhone(value){
      console.log(value)
      window.location.href = 'tel://'+value
    },
    openOverlay(){
      this.overlay = true
    },
    clearAll(value){
      if(value === 0){
        this.menuValueType = ""
        this.menuValueName = ""
        this.menuValueBenefit = ""
      }
      this.serviceTypeLength = 0
      this.finished = false
      this.page=0
      this.serviceList=[]
      this.getDataList('51')
    },
    getServiceName(){
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appserviceitem/list"),
        method: "post",
        params:this.$http.adornParams({
          page:0,
          limit:-1,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data.page.list)

          data.page.list.forEach(item =>{
            this.menuOptionName.push({value: item.id, text: item.name})
          })

        } else {
          this.$notify({type:"danger",message:data.msg});
        }
      });
    },
    toDetail(status,id,serviceId,applyUserId,subarea){
      this.$router.push({name:'request-detail',params:{status:status,id:id,serviceId:serviceId,applyUserId:applyUserId,subarea:subarea}})
    },
    changeType(name,title){
      this.serviceTypeLength = 0
      this.finished = false
      this.page=0
      this.serviceList=[]
      this.getDataList(name)
    },
    getDataList(type){
      if (type === '0'){
        this.$http({
          url: this.$http.adornUrl("/wxapp/app/appserviceapply/list"),
          method: "post",
          params:this.$http.adornParams({
            page:this.page++,
            limit:10,
            orgId: this.$orgId,
            provideUserId: this.$globalData.userInfo.userId,
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {

            data.page.list.forEach(item =>{
              if(item.status!==51){
                this.serviceList.push(item)
              }
            })
            this.loading = false
            // this.serviceTypeLength += data.page.list.length
            this.serviceTypeLength = this.serviceList.length
            if(data.page.list.length < 10){
              this.finished = true
            }

          } else {
            this.$notify({type:"danger",message:data.msg});
          }
        });
      }else if(type === '51'){
        this.$http({
          url: this.$http.adornUrl("/wxapp/app/appserviceapply/list"),
          method: "post",
          params:this.$http.adornParams({
            page:this.page++,
            limit:10,
            status: 51,
            name: this.menuValueName,
            typeId: this.menuValueType,
            // benefit: this.menuValueBenefit
            orgId: this.$orgId,
            serviceProfit:this.menuValueBenefit
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            data.page.list.forEach(item =>{
              this.serviceList.push(item)
            })
            this.loading = false
            this.serviceTypeLength += data.page.list.length
            if(data.page.list.length < 10){
              this.finished = true
            }
          } else {
            this.$notify({type:"danger",message:data.msg});
          }
        });
      }else{
        this.$http({
          url: this.$http.adornUrl("/wxapp/app/appserviceapply/list"),
          method: "post",
          params:this.$http.adornParams({
            page:this.page++,
            limit:10,
            orgId: this.$orgId,
            full: 1,
            status: type
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            data.page.list.forEach(item =>{
              if(item.status!==51){
                this.serviceList.push(item)
              }
            })
            this.loading = false
            this.serviceTypeLength += data.page.list.length
            if(data.page.list.length < 10){
              this.finished = true
            }
          } else {
            this.$notify({type:"danger",message:data.msg});
          }
        });
      }


    },

    getList(name,title){
      this.serviceTypeLength = 0
      this.finished = false
      this.page=0
      this.serviceList=[]
      if(name === '1'){
        this.getDataList('51')
      }else{
        this.getDataList('0')
      }
    },
    getServiceType(){
      this.$http({
        url:this.$http.adornUrl("/wxapp/sys/dict/listDictByCode"),
        params:this.$http.adornParams({
          orgId:this.$orgId,
          code:'serviceType'
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          data.dicts.forEach(item =>{
            item.text = item.label
          })
          this.menuOptionType = data.dicts
          console.log(this.menuOptionType)
        } else {
          this.$notify({type:"danger",message:data.msg});
        }
      });
    }
  }
}
</script>

<style scoped lang="scss" >
.list {
  font-size: 30px;
  padding: 30px 25px;

  .item {
    position: relative;
    width: 100%;
    background: #fff;
    padding: 30px 5px 30px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    //margin: 0 15px 30px 15px;
    margin-bottom: 30px;
  }

  .van-ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
  }
}

.head{
  display: flex;
  position: relative;
  line-height: 18px;


  .tag-status{
    transform: skewX(-20deg);
    font-size: 25px;
    padding: 3px 12px 0 13px;
    border-radius: 7px;
    font-weight: bold;
    color: white;
    .content{
      transform: skewX(20deg);
      line-height: 0.45rem;
    }



  }

  .name{
    margin-left: 0.25rem;
    font-size: 25px;
    line-height: 0.45rem;
  }
  .benefit{
    font-size: 25px;
    color: red;
    margin-top: 0.1rem;
  }

}

.typeName{
  font-size: 25px;
  color: #4589F6;
  margin-top: 15px;
  position:absolute;
  right: 2%
}
.info{
  display: flex;
  margin-top:0.5%;
  margin-left: 1%;
  font-size: 25px
}

.to-detail-btn{
  position: absolute;
  right: 5%;
  height:10px;
  width:6px;
}

.bg{
  background-color: #F5F5F5;
  min-height: 100vh;
  box-sizing: border-box;
}
.title-num{
  position:absolute;
  left:2%;
  display:inline-block;
  font-size: 25px;
  margin-top: 0.4rem
}
.van-dropdown-menu .van-dropdown-menu__bar{
  box-shadow: 0 0 0 0;
  background-color: red;
}

.goto-image{
  position: absolute;
  right: 5%;
  height:20px;
  width:12px;
}

.goto-image-other{
  margin-top: 2px;
  position: absolute;
  right: 5%;
  height:20px;
  width:12px
}

.phone-image{
  position: absolute;
  right: 5%;
  height:48px;
  width:48px;
}

.title-height{
  height: 40px;
  position: relative;
}

.all-image{
  height: 32px;
  width: 32px;
}

.location-image{
  height: 32px;
  width:26px;
  margin-left: 3px;
  margin-right: 3px;
}

.van-dropdown-menu ::v-deep .van-dropdown-menu__item:first-child .van-dropdown-menu__title{
  color:black !important;
}

.van-dropdown-menu ::v-deep .van-dropdown-menu__item:first-child .van-dropdown-menu__title::after{
  content: normal;
}
</style>
